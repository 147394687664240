



























import Vue from "vue";
import LineChart from "@/components/LineChart.vue";
import Component from "vue-class-component";
import { movingAvg, round } from "@/utils/stats";

interface RegionDayStats {
  data: string;
  stato: string;
  codice_regione: number;
  denominazione_regione: string;
  lat: number;
  long: number;
  ricoverati_con_sintomi: number;
  terapia_intensiva: number;
  totale_ospedalizzati: number;
  isolamento_domiciliare: number;
  totale_positivi: number;
  variazione_totale_positivi: number;
  nuovi_positivi: number;
  dimessi_guariti: number;
  deceduti: number;
  totale_casi: number;
  tamponi: number;
}

interface Region {
  code: string;
  name: string;
}

@Component({
  components: {
    LineChart,
  },
})
export default class Tamponi extends Vue {
  private loaded = false;
  private chartdata = {};
  private regions: Region[] = [];
  private region = "";
  private proCivData: RegionDayStats[] = [];
  private movingAverage = true;

  async mounted(): Promise<void> {
    this.loaded = false;

    if (this.$store.state.regionsData.length) {
      this.init(this.$store.state.regionsData);
      return;
    }

    fetch(
      "https://raw.githubusercontent.com/pcm-dpc/COVID-19/master/dati-json/dpc-covid19-ita-regioni.json"
    )
      .then((res) => res.json())
      .then((proCivData: RegionDayStats[]) => {
        this.$store.state.regionsData = proCivData;
        this.init(proCivData);
      });
  }

  private init(proCivData: RegionDayStats[]): void {
    this.proCivData = proCivData;

    const inserted: string[] = [];

    const regions: Region[] = proCivData
      .reduce((carrier: Region[], stats) => {
        carrier.push({
          code: stats.denominazione_regione,
          name: stats.denominazione_regione,
        });

        return carrier;
      }, [])
      .filter((region) => {
        if (inserted.indexOf(region.code) > -1) {
          return false;
        }

        inserted.push(region.code);

        return region.code;
      })
      .sort((reg1, reg2) => {
        if (reg1.code === "Emilia-Romagna") {
          return -10;
        }
        if (reg2.code === "Emilia-Romagna") {
          return 10;
        }
        return reg1.name.localeCompare(reg2.name);
      });

    this.regions = regions;
    this.region = regions[0].code;

    this.plot(this.region);
  }

  private plot(regionName: string) {
    const labels: string[] = [];
    let tests: number[] = [];
    let newTests: number[] = [];

    const dataToPlot = this.proCivData.filter(
      (stats) => stats.denominazione_regione === regionName
    );

    dataToPlot.forEach((dayStats, index, self) => {
      const date = new Date(dayStats.data.substr(0, 10));
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 90);
      if (date < threshold) {
        return;
      }

      labels.push(date.toLocaleDateString());
      let newDailyTests = dayStats.tamponi;
      if (index > 0) {
        newDailyTests = dayStats.tamponi - self[index - 1].tamponi;
      }
      if (newDailyTests < 0) {
        newDailyTests = 0;
      }
      const percentage = (dayStats.nuovi_positivi / newDailyTests) * 100;
      tests.push(Math.round(percentage * 100) / 100);
      newTests.push(newDailyTests);
    });

    if (this.movingAverage) {
      tests = round(movingAvg(tests), 2);
      newTests = round(movingAvg(newTests));
    }

    this.chartdata = {
      labels,
      datasets: [
        {
          label: "% nuovi casi / tamponi",
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: tests,
        },
        {
          label: "Tamponi giornalieri",
          backgroundColor: "pink",
          borderColor: "pink",
          fill: false,
          hidden: true,
          data: newTests,
        },
      ],
    };

    this.loaded = true;
  }

  private rePlot() {
    this.plot(this.region);
  }
}
